import React from "react"
import Layout from "@app-components/layout/"
import Background from "@app-components/utils/background"
import Positions from "@page-components/careers/positions"
import OfficeImage from "@page-components/careers/office"

const Careers = () => (
  <Layout page="careers" getStartedButtons={["demo", "get-started"]}>
    <Background tagName="section" className="bg-cover bg-no-repeat bg-center" type="red-single">
      <div
        style={{
          paddingTop: "8rem",
          paddingBottom: "8rem"
        }}
        className="white center bg-cover px2 md-px3 pb4">
        <h1>Careers</h1>
        <p className="large-p-tag max-width-2 mx-auto mt1">
          {`We're a Toronto-based tech startup changing the way people think about digital content distribution.`}
        </p>
      </div>
    </Background>
    <Background tagName="section" className="bg-cover bg-no-repeat bg-center" type="gray-right-rounded">
      <div className="max-width-4 mx-auto pt4 mb4">
        <div className="flex items-center">
          <div>
            <h2 className="mb1">Challenge Yourself</h2>
            <p>
              {`We’re a group of ambitious, talented, and focused individuals. We’re inspired and motivated by different
              things – some of us are globetrotters, others are movie-watching homebodies. Our team comprises foodies,
              mountain-bikers, futurists, socialites and innovators from all backgrounds. We work toward a common goal;
              to make an impact on the media industry by designing, engineering, and implementing pathbreaking
              distribution solutions. While our goals form common ground, it’s our differences that allow us to
              challenge convention and to excel as an innovative enterprise.`}
            </p>
          </div>
          <div className="pl4">
            <OfficeImage />
          </div>
        </div>
      </div>

      <Positions />
    </Background>
  </Layout>
)

export default Careers
