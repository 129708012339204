import React from "react"
import axios from "axios"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { css } from "@emotion/core"

class Positions extends React.Component {
  constructor() {
    super()

    this.state = {
      loading: true,
      jobs: []
    }
  }

  componentDidMount() {
    axios.get("https://jsapi.recruiterbox.com/v1/openings?client_name=bitcine").then(res => {
      this.setState({
        loading: false,
        jobs: res.data.objects
      })
    })
  }

  render() {
    const { loading, jobs } = this.state
    return (
      <section className="pt4 pb4 mb4">
        <div className="max-width-4 mx-auto px2">
          <h2 className="mb4 center">Open Positions</h2>
          <div className="flex items-start flex-wrap">
            {loading ? (
              "Loading..."
            ) : (
              <div className="col-12">
                {jobs.map(job => (
                  <OutboundLink
                    key={job.id}
                    href={job.hosted_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center p2"
                    style={{
                      fontSize: "1em"
                    }}
                    css={css`
                      border-radius: 5px;
                      transition: all 250ms;
                      background: rgba(0, 0, 0, 0);
                      &:hover {
                        background: rgba(0, 0, 0, 0.03);
                      }
                    `}>
                    <div className="flex-auto bold" style={{ fontSize: "1.2em" }}>
                      {job.title}
                    </div>
                    <div className="pl4 mr4">
                      Location: {job.location.city}, {job.location.state}, {job.location.country}
                    </div>
                    <div
                      className="justify-right"
                      style={{
                        width: "100px",
                        textTransform: "capitalize"
                      }}>
                      {job.position_type.replace("_", " ")}
                    </div>
                  </OutboundLink>
                ))}
              </div>
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default Positions
