import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const OfficeImage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/office.png/" }) {
          childImageSharp {
            fluid(maxWidth: 1400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Img style={{ width: "400px" }} alt="A photo of the CineSend office" fluid={data.file.childImageSharp.fluid} />
    )}
  />
)

export default OfficeImage
